import BarStats from "@/components/barStats";
import DataBox from "@/components/generic/dataBox";
import Header from "@/components/generic/header";
import GraphStats from "@/components/graphStats";
import Layout from "@/components/layout";
import { useStatsLastYearQuery } from "@/generated";
import { getMonthName } from "@/helper/getMonthName";
import { minuteStrToNumber } from "@/helper/minuteStrToNumber";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { PageProps } from "gatsby";
import React, { useEffect, useState } from "react";

const SupportPage: React.FC<PageProps> = () => {
  const { data, loading, error } = useStatsLastYearQuery();
  const [index, setIndex] = useState(0);

  const next = () => setIndex(index + 1);
  const prev = () => setIndex(index - 1);

  useEffect(() => {
    console.log({ loading, data });
  }, [loading, data]);

  if (error) {
    return (
      <Layout title="Helaas">
        <Container maxWidth="md">
          <Header>Helaas</Header>
          <Typography align="center">Er is iets mis gegaan.</Typography>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout title="Mijn Verbuik">
      <Container maxWidth="md">
        <Header>Verbruik</Header>

        {data?.authenticatedItem?.projects?.length === 0 && (
          <Typography align="center">Helaas is er nog geen informatie beschikbaar.</Typography>
        )}

        {data &&
          data.authenticatedItem?.projects?.map((project) => (
            <Box
              key={project.id}
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: { xs: "column", xl: "row-reverse" },
              }}
            >
              {project?.statsByMonth && project.statsByMonth.length > 0 && (
                <>
                  <DataBox
                    loading={loading}
                    header={
                      <>
                        {`${getMonthName(project.statsByMonth[index].month)}  ${project.statsByMonth[index].year}`}
                        <br />
                        <Typography variant="caption" sx={{ color: (theme) => theme.palette.tertiary.main }}>
                          {`minuten gebruikt: ${minuteStrToNumber(project.statsByMonth[index].minutes || "0")}`}
                        </Typography>
                      </>
                    }
                  >
                    <BarStats stats={project.statsByMonth[index]} buildMinutes={project.buildMinutes} />
                    <Box sx={{ display: "flex", justifyContent: "center", gap: 4, ml: 6 }}>
                      <Button color="tertiary" disabled={index === project.statsByMonth.length - 1} onClick={next}>
                        vorige
                      </Button>
                      <Button color="tertiary" disabled={index === 0} onClick={prev}>
                        volgende
                      </Button>
                    </Box>
                  </DataBox>

                  <GraphStats stats={project.statsByMonth} buildMinutes={project.buildMinutes} />
                </>
              )}
            </Box>
          ))}
      </Container>
    </Layout>
  );
};

export default SupportPage;
