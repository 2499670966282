import { StatsByMonthFragment } from "@/generated";
import { getMonthName } from "@/helper/getMonthName";
import { highest } from "@/helper/highest";
import { minuteStrToNumber } from "@/helper/minuteStrToNumber";
import { useTheme } from "@mui/material/styles";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";
import CenteredLoading from "./generic/centeredLoading";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Title, Legend);

type GraphStatsProps = {
  stats: StatsByMonthFragment[];
  buildMinutes?: number;
  loading?: boolean;
};

const createLabel = (stats: StatsByMonthFragment[]) => {
  const lastIndex = stats.length - 1;
  const first = stats[0];
  const last = stats[lastIndex];

  return `Verbruikte minuten per maand   ${first.month}/${first.year}   -   ${last.month}/${last.year}`;
};

const GraphStats: React.FC<GraphStatsProps> = ({ stats, buildMinutes: minutes, loading }) => {
  const reversedStats = [...stats].reverse();

  const buildMinutes = Number(minutes);
  const label = createLabel(reversedStats);
  const theme = useTheme();

  const labels = reversedStats.map(({ month }) => month && getMonthName(month));

  const data = reversedStats.map((stat) => minuteStrToNumber(stat.minutes || "0"));
  const highestDataPoint = data.reduce((acc, current) => highest(current, acc), 0);
  const maxScale = highest(buildMinutes, highestDataPoint + 10);

  if (loading) {
    return <CenteredLoading />;
  }

  return (
    <Line
      data={{
        labels,
        datasets: [
          {
            label: "Minuten Verbruikt",
            data,
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.dark,
          },
          {
            label: "Bundel per maand",
            data: data.map(() => buildMinutes),
            backgroundColor: theme.palette.tertiary.main,
            borderColor: theme.palette.tertiary.main,
            pointRadius: 0,
            borderWidth: 1,
            pointHitRadius: 0,
          },
        ],
      }}
      options={{
        responsive: true,
        plugins: {
          title: {
            align: "center",
            position: "top",
            display: true,
            color: theme.palette.tertiary.main,
            text: label,
          },
          legend: {
            position: "bottom",
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const minutes = reversedStats[tooltipItem.dataIndex]?.minutes;
                return `Minuten gebruikt: ${minutes}m`;
              },
              afterLabel: (tooltipItem) => {
                const stat = reversedStats[tooltipItem.dataIndex];
                const avg = stat?.avgMinutes;
                const runs = stat?.runs;

                return `Aantal rebuilds: ${runs}\nGemiddelde: ${avg}m`;
              },
            },
          },
        },
        scales: {
          y: {
            min: 0,
            max: maxScale,
          },
        },
      }}
    />
  );
};

export default GraphStats;
