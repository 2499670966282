import { StatsByMonthFragment } from "@/generated";
import { minuteStrToNumber } from "@/helper/minuteStrToNumber";
import { useTheme } from "@mui/material/styles";
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Tooltip } from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

type BarStatsProps = {
  stats: StatsByMonthFragment;
  buildMinutes?: number;
};

const BarStats: React.FC<BarStatsProps> = ({ stats, buildMinutes: minutes }) => {
  const buildMinutes = Number(minutes);
  const minutesUsed = minuteStrToNumber(stats.minutes || "0");
  const label = `minuten verbruikt`;
  const theme = useTheme();

  const backgroundColor = buildMinutes > minutesUsed ? theme.palette.primary.main : theme.palette.error.dark;

  return (
    <Bar
      height={300}
      data={{
        labels: [label],
        datasets: [
          {
            label: label,
            data: [minutesUsed],
            backgroundColor,
          },
        ],
      }}
      options={{
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            min: 0,
            max: buildMinutes,
            ticks: {
              count: 7,
            },
          },
        },
      }}
    />
  );
};

export default BarStats;
